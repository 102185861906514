.Header .dark-mode-switch {
    margin: 0;
}

.Header .dark-mode-switch .bp3-control-indicator .bp3-control-indicator-child .bp3-switch-inner-text {
    font-size: 0.5em !important;
}

.Header .membership-button {
    margin-right: 20px;
}