.Label {
    margin-left: 2px;
    margin-top: 2px;
    font-size: 0.7em !important;
    border: solid 1px transparent;
}

.Label.color-berry_red {
    color: #b8256f !important;
    background-color: rgba(184, 37, 111, 0.3) !important;
}

.Label.color-red {
    color: #db4035 !important;
    background-color: rgba(219, 64, 53, 0.3) !important;

}

.Label.color-orange {
    color: #cc6600 !important;
    background-color: rgba(255, 153, 51, 0.3) !important;
}

.Label.color-yellow {
    color: #fad000 !important;
    background-color: rgba(250, 208, 0, 0.3) !important;
}

.Label.color-olive_green {
    color: #afb83b !important;
    background-color: rgba(250, 208, 0, 0.3) !important;
}

.Label.color-lime_green {
    color: #7ecc49 !important;
    background-color: rgba(126, 204, 73, 0.3) !important;
}

.Label.color-green {
    color: #20742b !important;
    background-color: rgba(41, 148, 56, 0.3) !important;
}

.Label.color-mint_green {
    color: #6accbc !important;
    background-color: rgba(106, 204, 188, 0.3) !important;
}

.Label.color-teal {
    color: #158fad !important;
    background-color: rgba(21, 143, 173, 0.3) !important;
}

.Label.color-sky_blue {
    color: #066493 !important;
    background-color: rgba(20, 170, 245, 0.3) !important;
}

.Label.color-light_blue {
    color: #96c3eb !important;
    background-color: rgba(150, 195, 235, 0.3) !important;
}
.Label.color-blue {
    color: #4073ff !important;
    background-color: rgba(64, 115, 255, 0.3) !important;
}

.Label.color-grape {
    color: #884dff !important;
    background-color: rgba(136, 77, 255, 0.3) !important;
}

.Label.color-violet {
    color: #af38eb !important;
    background-color: rgba(175, 56, 235, 0.3) !important;
}

.Label.color-lavender {
    color: #eb96eb !important;
    background-color: rgba(235, 150, 235, 0.3) !important;
}

.Label.color-magenta {
    color: #e05194 !important;
    background-color: rgba(224, 81, 148, 0.3) !important;
}

.Label.color-salmon {
    color: #ff8d85 !important;
    background-color: rgba(255, 141, 133, 0.3) !important;
}

.Label.color-charcoal {
    color: #545454 !important;
    background-color: rgba(84, 84, 84, 0.1) !important;
}

*.bp3-dark .Label.color-charcoal {
    background-color: rgba(84, 84, 84, 0.4) !important;
    border: solid 1px rgba(157, 157, 157, 0.2);
}

.Label.color-grey {
    color: #b8b8b8 !important;
    background-color: rgba(184, 184, 184, 0.3) !important;
}

.Label.color-taupe {
    color: #ccac93 !important;
    background-color: rgba(204, 172, 147, 0.3) !important;
}

*.bp3-dark .Label {
    color: unset !important;
    border: solid 1px rgba(157, 157, 157, 0.2);
}