.NewTaskInput {
    padding: 5px;
    width: 100%;
}

.NewTaskInput .button {
    opacity: 0.4;
    cursor: pointer;
}

.NewTaskInput .button:hover {
    opacity: 1;
}

.NewTaskInput .input {
    width: 100%;
    padding-top: 5px;
}