.Column {
    border-radius: 5px;
    border: solid 1px transparent;
    background-color: #ebf1f5;
}

.App.bp3-dark .Column {
    background-color: #202b33;
}

.Column.draggable {
    cursor: grab;
}

.App.bp3-dark .Column:hover,
.App.bp3-dark .Column.isDragging {
    border: solid 1px grey;
}

.Column:hover,
.Column.isDragging {
    border: solid 1px #c3c3c3;
}

.Column .column-title.disabled {
    cursor: not-allowed !important;
}

.Column .items-wrapper .items .drop-zone {
    min-height: 10px;
}

.Column .items-wrapper .items.isDraggingOver .drop-zone {
    min-height: 20px;
}
