.FilterItems {
    width: 350px;
    padding: 10px;
}

.FilterItems > * {
    margin-bottom: 10px;
}

.FilterItems > *:last-child {
    margin-bottom: 0;
}