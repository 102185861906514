.BoardProperties {
    width: 300px;
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.BoardProperties .field-switch {
    margin-bottom: 12px;
}

.BoardProperties .field-switch:last-of-type {
    margin-bottom: 0;
}