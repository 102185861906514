body {
    padding: 0 !important;
    margin: 0 !important;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.App .Page {
    width: 100%;
    flex: 1;
    background-color: #ebf1f5;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    min-height: 0;
    overflow: auto;
}

.App.bp3-dark .Page {
    background-color: #202b33;
}

.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'DM Serif Display', serif;
}

.h2 {
    font-size: 2.5rem;
    line-height: 3rem;
}

.h3 {
    font-size: 2rem;
    line-height: 2.5rem;
}

.h4 {
    font-size: 1.5rem;
    line-height: 2rem;
}

.flex-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flex-row + .flex-row {
    margin-top: 5px;
}

.flex-column {
    width: 100%;
    display: flex;
    flex-direction: column;
}

::-webkit-scrollbar {
    width: 4px; /* for vertical scrollbars */
    height: 12px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.margin-0 {
    margin: 0 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.link-text {
    text-decoration: underline;
    cursor: pointer;
}

.pointer-events-none {
    pointer-events: none;
    user-select: none;
}
