.Section {
    width: 100%;
    padding: 0.5rem;
}

.Section .section-inner {
    max-width: 1360px;
    margin: 0 auto;
}

.text-align-center {
    text-align: center;
}

#hero {
    padding-top: 10vh;
    padding-bottom: 5vh;
}

#hero .heading {
    font-size: 4.5rem;
}

#hero .sub-header {
    margin: 1rem;
    padding: 2rem;
}

#hero .sub-header div {
    font-size: 1.7em;
    line-height: 2.4rem;
}

@media screen and (max-width: 730px) {
    #hero .heading {
        font-size: 3rem;
    }

    #hero .sub-header div {
        font-size: 1.5em;
        line-height: 2rem;
    }
}

@media screen and (max-width: 490px) {
    #hero .heading {
        font-size: 2rem;
    }

    #hero .sub-header {
        margin: 1rem;
        padding: 1rem;
    }

    #hero .sub-header div {
        font-size: 1em;
        line-height: 1.4rem;
    }
}

#hero #cta {
    background-color: #ff4c40;
    border-radius: 50px;
}

#hero #cta:hover {
    background-color: #ff281a;
}

#screenshot {
    max-width: 100%;
    height: auto;
}
