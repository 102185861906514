.Item {
    word-break: break-word;
    padding: 1px;
}

.Item.loading,
.Item.checked {
    opacity: 0.6;
}

.Item .bp3-checkbox.rounded.disabled {
    opacity: 0.2;
}

.Item .bp3-checkbox.rounded > .bp3-control-indicator {
    border-radius: 50%;
}

.Item .bp3-checkbox.rounded > .bp3-control-indicator::before {
    /* take into account the 1px border */
    margin-left: -1px;
    margin-top: -1px;
}

*:not(.bp3-dark) .Item .bp3-checkbox.rounded input:checked ~ .bp3-control-indicator::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='dimgrey'/%3e%3c/svg%3e");
}

*.bp3-dark .Item .bp3-checkbox.rounded input:checked ~ .bp3-control-indicator::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='white'/%3e%3c/svg%3e");
}

.Item .bp3-checkbox.rounded.priority-1 > .bp3-control-indicator {
    border: solid 1px rgb(184, 184, 184);
    background: transparent;
}

.Item .bp3-checkbox.rounded.priority-1:hover > .bp3-control-indicator,
.Item .bp3-checkbox.rounded.priority-1 > .bp3-control-indicator:hover {
    background: rgba(127, 127, 127, 0.2) none;
}

.Item .bp3-checkbox.rounded.priority-2 > .bp3-control-indicator {
    border: solid 1px rgb(82, 151, 255);
    background: rgba(82, 151, 255, 0.5) none;
}

.Item .bp3-checkbox.rounded.priority-2:hover > .bp3-control-indicator,
.Item .bp3-checkbox.rounded.priority-2 > .bp3-control-indicator:hover {
    background: rgba(82, 151, 255, 0.7) none;
}

.Item .bp3-checkbox.rounded.priority-3 > .bp3-control-indicator {
    border: solid 1px rgb(255, 154, 20);
    background: rgba(255, 154, 20, 0.5) none;
}
.Item .bp3-checkbox.rounded.priority-3:hover > .bp3-control-indicator,
.Item .bp3-checkbox.rounded.priority-3 > .bp3-control-indicator:hover {
    background: rgba(255, 154, 20, 0.7) none;
}

.Item .bp3-checkbox.rounded.priority-4 > .bp3-control-indicator {
    border: solid 1px rgb(255, 112, 102) !important;
    background: rgba(255, 112, 102, 0.5) none !important;
}
.Item .bp3-checkbox.rounded.priority-4:hover > .bp3-control-indicator,
.Item .bp3-checkbox.rounded.priority-4 > .bp3-control-indicator:hover {
    background: rgba(255, 112, 102, 0.7) none !important;
}

.Item > .color {
    border-bottom: solid 5px transparent;
}

/* Todoist colors - https://developer.todoist.com/sync/v8/#colors */
.Item > .color-berry_red {
    border-bottom-color: #b8256f;
}
.Item > .color-red {
    border-bottom-color: #db4035;
}
.Item > .color-orange {
    border-bottom-color: #ff9933;
}
.Item > .color-yellow {
    border-bottom-color: #fad000;
}
.Item > .color-olive_green {
    border-bottom-color: #afb83b;
}
.Item > .color-lime_green {
    border-bottom-color: #7ecc49;
}
.Item > .color-green {
    border-bottom-color: #299438;
}
.Item > .color-mint_green {
    border-bottom-color: #6accbc;
}
.Item > .color-teal {
    border-bottom-color: #158fad;
}
.Item > .color-sky_blue {
    border-bottom-color: #14aaf5;
}
.Item > .color-light_blue {
    border-bottom-color: #96c3eb;
}
.Item > .color-blue {
    border-bottom-color: #4073ff;
}
.Item > .color-grape {
    border-bottom-color: #884dff;
}
.Item > .color-violet {
    border-bottom-color: #af38eb;
}
.Item > .color-lavender {
    border-bottom-color: #eb96eb;
}
.Item > .color-magenta {
    border-bottom-color: #e05194;
}
.Item > .color-salmon {
    border-bottom-color: #ff8d85;
}
.Item > .color-charcoal {
    border-bottom-color: #808080;
}
.Item > .color-grey {
    border-bottom-color: #b8b8b8;
}
.Item > .color-taupe {
    border-bottom-color: #ccac93;
}

/* Priority colors */
.Item > .color-p1 {
    border-bottom-color: #d1453b;
}
.Item > .color-p2 {
    border-bottom-color: #eb8907;
}
.Item > .color-p3 {
    border-bottom-color: #256fe0;
}
.Item > .color-p4 {
    border-bottom-color: #848484;
}

.Item .todoist-link,
.Item .hover-icon {
    visibility: hidden;
}

.Item:hover .todoist-link,
.Item:hover .hover-icon {
    visibility: visible;
}

.ItemProject,
.ItemDueDate {
    cursor: pointer;
}

.ItemProject:hover,
.ItemDueDate:hover {
    text-decoration: underline;
}

.ItemAssignee,
.ItemDueDate {
    display: flex;
}