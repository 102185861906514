.MembershipDialog {
    padding-bottom: 0;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
}

.MembershipDialog:has(.footer) {
    padding-bottom: 20px;
}

.MembershipDialog .form {
    margin-top: 20px;
}

.MembershipDialog .SubscriptionOption {
    cursor: pointer;
    margin-bottom: 10px;
    user-select: none;
}

.MembershipDialog .SubscriptionOption + .SubscriptionOption {
    margin-top: 10px;
}

.MembershipDialog .checkout-button {
    background-color: rgba(255, 76, 64, 100);
}

.MembershipDialog .checkout-button:hover,
.MembershipDialog .checkout-button.disabled {
    background-color: rgba(255, 40, 26, 100);
}

.MembershipDialog .pay-what-you-want-input {
    margin: 0 3px;
}
