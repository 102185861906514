/**
 * Stylesheet for managing the dynamic layout and size of the Board and Columns.
 *
 */

.Board {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.Board .ColumnPanel {
    height: 100%;
    min-height: 0;
}

.Board .ColumnPanel .columns-wrapper-padding {
    padding: 0 20px 10px 20px;
    height: 100%;
}

.Board .ColumnPanel .columns-wrapper {
    padding: 5px 0;
}

.Board .ColumnPanel .columns-wrapper,
.Board .ColumnPanel .columns-wrapper-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    height: 100%;
    overflow-x: auto;
    user-select: none;
}

.Board .ColumnPanel .Column {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    flex-grow: 0;
    flex-shrink: 0;
}

.Column .title {
    padding-left: 5px;
    flex-shrink: 0;
}

.Column .items-wrapper {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    padding: 5px 0 5px 5px;
}

.Column .items-wrapper .items {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
}

.Column .items .Item {
    margin-bottom: 10px;
}

.Column .items .Item:last-of-type {
    margin-bottom: 0 !important;
}
