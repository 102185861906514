:root {
    --padding: 10px;
}

.UserCard {
    padding: var(--padding);
    display: flex;
    flex-direction: row;
}

.UserCard .image {
    margin-right: var(--padding);
    display: flex;
    align-items: center;
}
